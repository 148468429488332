export const env = false;

//接口域名地址
let url = env ? "https://life-api.xiangshuheika.com" : 'https://api-stag-xs.xiangshuheika.com' 

//阿里云api网关使用的key，secret
export const key = env ? "203810451" : '203810450' ;
export const secret = env ? "fa70o4nrgiqrz7tk2f20gksluh7qyg3s" : '6qvbhkluygnkm58b49pcphdomh1q5d9o' ;

export const baseUrl = url;

//会员行业现状报告下载地址
export const industrialreports = 'https://newoakvip.oss-cn-shenzhen.aliyuncs.com/h5/%E6%96%B0%E5%BB%BA%20Microsoft%20PowerPoint%20%E6%BC%94%E7%A4%BA%E6%96%87%E7%A8%BF.pptx'

//如何科学搭建会员体系下载地址
export const setupmembersystem = 'https://newoakvip.oss-cn-shenzhen.aliyuncs.com/h5/%E6%96%B0%E5%BB%BA%20Microsoft%20PowerPoint%20%E6%BC%94%E7%A4%BA%E6%96%87%E7%A8%BF.pptx'

export const phone = '400-150-9669'
export const email = 'marketing@oakblack.com'
export const service_url = 'https://oakvip.s2.udesk.cn/im_client/?web_plugin_id=28877'

//易观数据上报地址
export const ans_url = env ? 'https://argo2-api.xiangshuheika.com/' : 'https://argo-api-stag.xiangshuheika.com/'
//易观数据appkey
export const ans_key = env ? '891c4150e4c1dae8' : '7a977980c26cd91c'