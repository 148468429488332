import React from 'react';
import './case.css';
import Header from '../../components/header/header'
import Footer from '../../components/footer/footer'
import oak from '../../utils'

class Case extends React.Component {
  constructor(props){
    super(props)
    this.state={
      partner_category: ['银行', '金融科技', '保险', '汽车', '智能终端', '教育'],
      categary_selected: 0,
      partner_list: [
        [
          {
            img: [require('../../images/case/icbc.png'), require('../../images/case/pf.png'), require('../../images/case/zs.png')],
            logo: [require('../../images/case/icbc_logo.png'), require('../../images/case/pf_logo.png'), require('../../images/case/zs_logo.png')],
            desc: '银行面向本行高端用户，使用橡树定制化会员服务，用户进入银行APP可订购会员，通过积分支付、自动续费等支付方式，让用户可轻松享受品质出行礼遇（覆盖优惠住星级酒店、全球机场高铁贵宾休息室、接送机、5折机场餐厅、快速安检等），从而提升用户活跃与银行中收'
          },
          {
            img: [require('../../images/case/wn.png'), require('../../images/case/wn2.png')],
            logo: [require('../../images/case/wn_logo.png')],
            desc: '使用橡树定制化会员服务，包含星级酒店权益、休息室、京东e卡等，定向免费赠送给本行高端用户，从而提升银行口碑与核心用户忠诚度'
          }
        ],
        [
          {
            img: [require('../../images/case/lct.png'), require('../../images/case/lct2.png'), require('../../images/case/lct3.png')],
            logo: [require('../../images/case/lct_logo.png')],
            desc: '使用橡树定制化权益服务，面向理财高端客户推出高端稀缺的会员权益服务和活动，极大地提升了产品吸引力和口碑，吸引新用户，提升老用户满意度；通过不同会员等级不同权益力度的设置，提升了老用户理财金额复投频次'
          },
          {
            img: [require('../../images/case/sy.png'), require('../../images/case/xy.png'), require('../../images/case/za.png')],
            logo: [require('../../images/case/financial_logo.png')],
            desc: '使用橡树定制化会员服务，面向用户推出付费会员服务，基于用户特征，通过自身权益与高价值/高频生活类、出行权益的结合，满足用户需求，效果显著，不仅获得快速上升的会员费收入，同时也提升了核心用户忠诚度，会员比非会员用户次月留存率高162%'
          }
        ],
        [
          {
            img: [require('../../images/case/gr.png'), require('../../images/case/gr2.png')],
            logo: [require('../../images/case/gr_logo.png')],
            desc: '使用橡树定制化会员服务，包含星级酒店权益、休息室等，定向免费赠送给高端用户，从而提升保险口碑与核心用户忠诚度，进而提升用户在主营业务的复购率'
          }
        ],
        [
          {
            img: [require('../../images/case/gq.png'), require('../../images/case/lkss.png')],
            logo: [require('../../images/case/gq_logo.png'), require('../../images/case/lkss_logo.png')],
            desc: '使用橡树定制化会员服务，围绕着车生活，包含星级酒店、车养护权益等，作为新人礼，定向免费赠送给新购车的用户，提升在产品竞争力和形象，进而提升销量'
          }
        ],
        [
          {
            img: [require('../../images/case/Samsung.png'), require('../../images/case/galaxy.png')],
            logo: [require('../../images/case/samsung_logo.png')],
            desc: '三星手机使用橡树定制化会员产品，结合自身服务，作为新品手机Galaxy  Z Flip Thorm Browne的尊享权益，利用高端权益服务吸引目标用户，提升手机销量'
          }
        ],
        [
          {
            img: [require('../../images/case/xg.png'), require('../../images/case/xg2.png')],
            logo: [require('../../images/case/xg_logo.png')],
            desc: '使用橡树定制化会员服务，面向2000万儿童的父母推出付费会员服务，基于用户特征，通过自身权益与高价值/高频生活类的结合，极大丰富了产品的服务场景，满足用户需求，不仅获得的会员费收入，同时也提升了核心用户忠诚度'
          }
        ],
      ]
    }
  }
  componentDidMount(){
    oak.ans.pageView('官网合作案例浏览', {namespace: 'xs_web'})
    const { partner_category } = this.state;
    document.documentElement.scrollTop = 0;
    let storageName = sessionStorage.getItem('partner_category');
    let name = storageName;
    try {
      name = this.props.location.query.name
    } catch {
      //无
    }
    for(let i = 0; i < partner_category.length; i++){
      if(name === partner_category[i]){
        
        this.setState({
          categary_selected: i
        })
      }
    }
    sessionStorage.setItem('partner_category', name)
  }
  changeCategory(index){
    window._hmt.push(['_trackEvent', '合作案例', '【按钮】', this.state.partner_category[index]]);
    this.setState({
      categary_selected: index
    })
  }
  render() {
    const { partner_category, categary_selected, partner_list } = this.state;
    return (
      <div>
        <Header selected={1} />
        <div className='banner pc-banner'>
          <img alt='' src={require('../../images/case.png')} />
        </div>
        <div className='banner mobile-banner'>
          <img alt='' src={require('../../images/mobile-case.png')} />
        </div>
        <div className='partner-category'>
          {
            partner_category.map((item, index) => {
              return (
                <div onClick={this.changeCategory.bind(this, index)} className={categary_selected === index ? 'partner-item active' : 'partner-item'} key={item}>{item}</div>
              )
            })
          }
        </div>
        <div className='partner-list container'>
          {
            partner_list[categary_selected].map((item) => {
              return (
                <div className='partner-list-item' key={item.desc}>
                  <div className='partner-img-list'>
                    {
                      item.img.map((i) => {
                        return (
                          <div className='partner-img-item' key={i}>
                            <img alt='' src={i} />
                            <span></span>
                          </div>
                        )
                      })
                    }
                  </div>
                  <div className='partner-main'>
                    <div className='partner-logo-list'>
                      {
                        item.logo.map((l) => {
                          return (
                            <div className='partner-logo' key={l}>
                              <img alt='' src={l} />
                            </div>
                          )
                        })
                      }
                    </div>
                    <p>{item.desc}</p>
                  </div>
                </div>
              )
            })
          }
        </div>
        <Footer />
      </div>
    )
  }
}
export default Case;
