import React from 'react';
import './home.css';
import 'swiper/swiper-bundle.css'
import Header from '../../components/header/header'
import Footer from '../../components/footer/footer'
import { Carousel } from 'antd';
import Swiper from 'swiper/swiper-bundle.min.js'
import { getIndexCategory, getspusList } from '../../config/api'
import { industrialreports, setupmembersystem } from '../../config/env'
import Getverifycode from '../../components/getverifycode/getverifycode'
import oak from '../../utils'

class Home extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      showdialog: false,
      bannerList: [
        {url: require('../../images/banner.png')},
      ],
      mobile_bannerList: [
        {url: require('../../images/mobile-banner.png')},
      ],
      categaryList: [],
      categary_selected: 0,
      spuList: [],
      serve_list: [
        {name: '产品', desc: '从企业及用户特征出发，搭建会员体系和权益', img: require('../../images/serve1.png')},
        {name: '运营', desc: '提供会员运营支持和数据决策，专业客服售后服务', img: require('../../images/serve2.png')},
        {name: '技术', desc: '支持H5/API/兑换码等接入，支持灵活 快速定制，安全保障', img: require('../../images/serve3.png')},
        {name: '供应链', desc: '跨场景/独家的高价值、多类型权益资源，可按需 定制和拓展资源', img: require('../../images/serve4.png')}
      ],
      value_list: [
        {
          name: '活跃', 
          img: require('../../images/value1.png'), 
          list: ['• 提升用户行为活跃','• 提升用户交易活跃','• 延长用户生命周期']
        },
        {
          name: '创收', 
          img: require('../../images/value2.png'), 
          list: ['• 获得会员费营收','• 带动主营业务增收']
        },
        {
          name: '拉新', 
          img: require('../../images/value3.png'), 
          list: ['• 利用会员与多品类的权益，场景化获客']
        },
        {
          name: '客户关怀', 
          img: require('../../images/value4.png'), 
          list: ['• 通过赠送会员，提升品牌价值','• 提升用户尊贵感','• 提升用户归属感']
        },
      ],
      advantage_list: [require('../../images/advantage1.png'), require('../../images/advantage2.png'), require('../../images/advantage3.png'), require('../../images/advantage4.png')]
    }
  }
  componentDidMount(){
    oak.ans.pageView('官网首页浏览', {namespace: 'xs_web'})
    document.documentElement.scrollTop = 0;
    //获取类目
    getIndexCategory({namespace: 'xs_web', opened_page: 'benefit'}).then(res => {
      this.setState({
        categaryList: res.data
      })
      return this.getSpusList(res.data[0].name)
    })
    window.addEventListener('scroll', this.handleScroll);
  }
  componentWillUnmount(){
    window.removeEventListener('scroll', this.handleScroll);
  }
  //获取spu列表
  getSpusList(name){
    let opts = {
      namespace: 'xs_web',
      title: name,
      opened_page: 'benefit'
    }
    getspusList(opts).then(res => {
      this.setState({
        spuList: res.data[name]
      }, () => {
        let swiper = new Swiper('.swiper-container', {
          slidesPerView: 4,
          slidesPerGroup: 8,
          slidesPerColumn: 2,
          slidesPerColumnFill: 'row',
          pagination: {
            el: '.swiper-pagination',
            clickable: true,
          },
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
        });
      })
    })
  }
  //切换类目
  selectCatogary(index){
    window._hmt.push(['_trackEvent', '产品服务', this.state.categaryList[index].name]);
    this.setState({
      categary_selected: index
    })
    this.getSpusList(this.state.categaryList[index].name)
  }

  download(url, name){
    window._hmt.push(['_trackEvent', '产品服务', '下载', name]);
    var verify = sessionStorage.getItem('verify');
    if(!verify){
      this.setState({
        showdialog: true,
        download_file: url
      })
    }else{
      window.open(url);
    }
  }

  hideDialog(){
    this.setState({
      showdialog: false
    })
  }

  handleScroll=()=>{
    //滚动条高度
    let clientHeight = document.documentElement.clientHeight; //可视区域高度
    let scrollTop  = document.documentElement.scrollTop;  //滚动条滚动高度
    // const sreviceTitle = this.sreviceTitle.offsetTop;

    const enterViewport = clientHeight+scrollTop;
    // if(sreviceTitle < enterViewport){ this.sreviceTitle.classList.add("fadeInUp")}
  }
  render() {
    const { bannerList, mobile_bannerList, showdialog, download_file, serve_list, value_list, categaryList, categary_selected, spuList, advantage_list } = this.state;
    return (
      <div>
        <Header selected={0} />
        <div className='carousel-container pc-carousel'>
          <Carousel 
            className='carousel' 
            autoplay 
            draggable 
            effect='fade'>
            {
              bannerList.map((item) => {
                return (
                  <div key={item.url}>
                    <img alt='' src={item.url} />
                  </div>
                )
              })
            }
          </Carousel>
        </div>
        <div className='carousel-container mobile-carousel'>
          <Carousel 
            className='carousel' 
            autoplay 
            draggable 
            effect='fade'>
            {
              mobile_bannerList.map((item) => {
                return (
                  <div key={item.url}>
                    <img alt='' src={item.url} />
                  </div>
                )
              })
            }
          </Carousel>
        </div>
        {/* <div className='download-file'>
            <div className='download-li' onClick={this.download.bind(this, industrialreports, '《会员行业现状报告》')}><span>免费下载</span>《会员行业现状报告》</div>
            <div className='download-li' onClick={this.download.bind(this, setupmembersystem, '《如何科学搭建会员体系》')}><span>免费下载</span>《如何科学搭建会员体系》</div>
        </div> */}
        <div className='benefit-list container'>
          <div className='home-title fadeInUp'>
            <div className='home-title-t1'>产品服务</div>
            <div className='home-title-en'>助力企业高效率、低成本搭建会员/权益体系，拓展产品服务与吸引力</div>
          </div>
          <div className='serve-list'>
            <ul>
              {
                serve_list.map((item) => {
                  return (
                    <li key={item.name}>
                      <img alt="" src={item.img} />
                      <h2>{item.name}</h2>
                      <p>{item.desc}</p>
                    </li>
                  )
                })
              }
            </ul>
          </div>
        </div>
        <div className='value-wrap'>
          <div className='container'>
            <div className='home-title fadeInUp'>
              <div className='home-title-t1'>服务价值</div>
              <div className='home-title-en'>助力企业满足用户对高品质服务的需求，提升拉新促活能力与品牌忠诚度</div>
            </div>
            <div className='value-list'>
              <ul>
                {
                  value_list.map((item) => {
                    return (
                      <li key={item.name}>
                        <img alt="" src={item.img} />
                        <h2>{item.name}</h2>
                        {
                          item.list.map((i) => {
                            return (
                              <p key={i}>{i}</p>
                            )
                          })
                        }
                      </li>
                    )
                  })
                }
              </ul>
            </div>
          </div>
        </div>
        <div className='benefit-warp container'>
          <div className='home-title fadeInUp'>
            <div className='home-title-t1'>数据化运营 个性化定制</div>
          </div>
          <div className='benefit-main'>
            <div className='benefit-catogary'>
              {
                categaryList.map((item, index) => {
                  return (
                    <div className='benefit-catogary-item' key={item.name}>
                      <div className={categary_selected === index ? 'benefit-catogary-item-text active' : 'benefit-catogary-item-text'} onClick={this.selectCatogary.bind(this, index)}>{item.name}</div>
                    </div>
                  )
                })
              }
            </div>
            <div className='spu-list'>
              <div className='swiper-container'>
                <div className="swiper-wrapper swiper-no-swiping">
                  {
                    spuList.map((item) => {
                      return (
                        <div className='spu-item swiper-slide' key={item.name}>
                          <div className='spu-item-wrap'>
                            <img alt='' className='spu-item-img' src={item.display_img_url} />
                            <div className='spu-item-name'>{item.name}</div>
                            <div className='spu-item-desc'>{item.selling_points}</div>
                          </div>
                        </div>
                      )
                    })
                  }
                </div>
                <div className="swiper-pagination"></div>
                <div className="swiper-button-next"></div>
                <div className="swiper-button-prev"></div>
              </div>
            </div>
          </div>
        </div>
        <div className='advantage'>
          <div className='container'>
            <div className='home-title fadeInUp'>
              <div className='home-title-t1'>产品优势</div>
            </div>
            <div className='advantage-list'>
              {
                advantage_list.map((item) => {
                  return (
                    <div className='advantage-item' key={item}>
                      <img alt='' src={item} />
                    </div>
                  )
                })
              }
            </div>
          </div>
        </div>
        <div className='partner container'>
          <div className='home-title fadeInUp'>
            <div className='home-title-t1'>我们服务的行业客户</div>
          </div>
          <div className='partner-wrap pc-partner'>
            <img alt='' src={require('../../images/partner.png')}></img>
          </div>
          <div className='partner-wrap mobile-partner'>
            <img alt='' src={require('../../images/mobile-partner.png')}></img>
          </div>
        </div>
        <Footer />
        {
          showdialog
          ? (
            <Getverifycode
              download_file={download_file}
              onDownload={this.download.bind(this)}
              OnhideDialog={this.hideDialog.bind(this)} />
          ) : null
        }
      </div>
    )
  }
}
export default Home;
