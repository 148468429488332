import React from 'react';
import { Switch, Route } from 'react-router-dom'
import './App.css';
import Case from './pages/case/case'
import Home from './pages/index/home'
import Contact from './pages/contact/contact'
import { env, ans_url, ans_key } from './config/env';
import oak from './utils'

class App extends React.Component {

  componentDidMount() {
    oak.ans.init({
      appkey: ans_key,//APPKEY
      uploadURL: ans_url,//上传数据的地址
      visitorConfigURL: ans_url,
      SDKFileDirectory: 'http://cdn.xiangshuheika.com/h5/commonjs/',
      autoTrack: true,
      debugMode: env ? 0 : 2
    })
    
  }

  render() {
    return (
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/case" component={Case} />
        <Route path="/contact" component={Contact} />
      </Switch>
    )
  }
}
export default App;
