import React from 'react';
import { Link } from 'react-router-dom'
import './footer.css';
import { industrialreports, setupmembersystem, phone, email, service_url } from '../../config/env'
import Getverifycode from '../../components/getverifycode/getverifycode'
import oak from '../../utils'

class Footer extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      case_list: ['银行', '金融科技', '保险', '汽车', '智能终端', '教育']
    }
  }
  download(url, name){
    window._hmt.push(['_trackEvent', '底部导航', '下载', name]);
    var verify = sessionStorage.getItem('verify');
    if(!verify){
      this.setState({
        showdialog: true,
        download_file: url
      })
    }else{
      window.open(url);
    }
  }

  hideDialog(){
    this.setState({
      showdialog: false
    })
  }
  toService(){
    oak.ans.track('oak_consult_click', {namespace: 'xs_web'});
    window._hmt.push(['_trackEvent', '悬浮按钮', '在线咨询']);
    window.open(service_url)
  }
  tobaidu(name){
    window._hmt.push(['_trackEvent', '底部导航', name]);
  }
  render() {
    const { showdialog, download_file, case_list } = this.state;
    return (
      <div className='footer'>
        <div className='container pc-footer'>
          <div className='footer-l'>
            <img className='footer-logo' alt='' src={require('../../images/logo2.png')} />
            <p>合作电话：{phone}</p>
            <p>业务合作：{email}</p>
            <p>媒体合作：media@oakblack.com</p>
          </div>
          <div className='footer-r'>
            <div className='footer-r-item'>
              <div className='footer-item-title'><Link to='/'>产品服务</Link></div>
              <p ><Link to='/'>首页</Link></p>
              {/* <p onClick={this.download.bind(this, industrialreports, '《会员行业现状报告》')}>免费下载《会员行业现状报告》</p>
              <p onClick={this.download.bind(this, setupmembersystem, '《会员行业现状报告》')}>免费下载《会员行业现状报告》</p> */}
            </div>
            <div className='footer-r-item'>
              <div className='footer-item-title'><Link to='/case'>合作案例</Link></div>
              {
                case_list.map((item) => {
                  return (
                  <p key={item} onClick={this.tobaidu.bind(this, item)}><Link to={{
                    pathname: '/case',
                    query : {name: item}
                  }}>{item}</Link></p>
                  )
                })
              }
            </div>
            <div className='footer-r-item'>
              <div className='footer-item-title' onClick={this.tobaidu.bind(this, '联系我们')}><Link to='/contact'>联系我们</Link></div>
              <p onClick={this.tobaidu.bind(this, '公司介绍')}><Link to='/contact'>公司介绍</Link></p>
              <p onClick={this.tobaidu.bind(this, '联系我们')}><Link to='/contact'>联系我们</Link></p>
              <p onClick={this.tobaidu.bind(this, '加入我们')}><a target="_blank" rel="noopener noreferrer" href='https://www.lagou.com/gongsi/594252.html'>加入我们</a></p>
            </div>
          </div>
          <div className='clearfix'></div>
          <div className='copyright'><a target='_blank' rel="noopener noreferrer" href='http://www.beian.miit.gov.cn/'>粤ICP备20063673号</a>    Copyright © 2018 深圳市高光时刻网络科技有限公司</div>
        </div>
        <div className='container mobile-footer'>
          <div className='mobile-footer-item'>
            <div className='footer-r-item mobile-footer-item-width'>
              <div className='footer-item-title'>产品服务</div>
              <p><Link to='/'>首页</Link></p>
              {/* <p onClick={this.download.bind(this, industrialreports, '《会员行业现状报告》')}>免费下载《会员行业现状报告》</p>
              <p onClick={this.download.bind(this, setupmembersystem, '《会员行业现状报告》')}>免费下载《会员行业现状报告》</p> */}
            </div>
            <div className='footer-r-item'>
              <div className='footer-item-title'><Link to='/case'>合作案例</Link></div>
              {
                case_list.map((item) => {
                  return (
                  <p key={item} onClick={this.tobaidu.bind(this, item)}><Link to={{
                    pathname: '/case',
                    query : {name: item}
                  }}>{item}</Link></p>
                  )
                })
              }
            </div>
          </div>
          <div className='mobile-footer-item'>
            <div className='footer-l footer-r-item mobile-footer-item-width'>
              <img className='footer-logo' alt='' src={require('../../images/logo2.png')} />
              <p>合作电话：{phone}</p>
              <p>业务合作：{email}</p>
              <p>媒体合作：media@oakblack.com</p>
            </div>
            <div className='footer-r-item'>
              <div className='footer-item-title' onClick={this.tobaidu.bind(this, '联系我们')}><Link to='/contact'>联系我们</Link></div>
              <p onClick={this.tobaidu.bind(this, '公司介绍')}><Link to='/contact'>公司介绍</Link></p>
              <p onClick={this.tobaidu.bind(this, '联系我们')}><Link to='/contact'>联系我们</Link></p>
              <p onClick={this.tobaidu.bind(this, '加入我们')}><a target="_blank" rel="noopener noreferrer" href='https://www.lagou.com/gongsi/594252.html'>加入我们</a></p>
            </div>
          </div>
          <div className='copyright'><span><a target='_blank' rel="noopener noreferrer" href='http://www.beian.miit.gov.cn/'>粤ICP备20063673号</a>    Copyright © 2018 </span>深圳市高光时刻网络科技有限公司</div>
        </div>
        <div className='advice'>
          <div className='advice-item' onClick={this.toService.bind(this)}>
            <img alt='' src={require('../../images/icon3.png')} />
            <p>在线咨询</p>
          </div>
          <div className='advice-item advice-event'>
            <a href='tel:400-150-9669'>
              <img alt='' src={require('../../images/icon4.png')} />
              <p>电话咨询</p>
              <div className='advice-phone'>
                客服电话：
                <h4>{phone}</h4>
              </div>
            </a>
          </div>
        </div>
        {
          showdialog
          ? (
            <Getverifycode
              download_file={download_file}
              onDownload={this.download.bind(this)}
              OnhideDialog={this.hideDialog.bind(this)} />
          ) : null
        }
      </div>
    )
  }
}
export default Footer;
