import React from 'react';
import './header.css';
import { Link } from 'react-router-dom'
import { phone } from '../../config/env'

class Header extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      navList: [
        {name: '产品服务', id: 11, to: '/'},
        {name: '合作案例', id: 21, to: '/case'},
        {name: '联系我们', id: 31, to: '/contact'}
      ],
    }
  }
  render() {
    const { navList } = this.state;
    const { selected } = this.props;
    return (
      <div className='header'>
        <div className='container'>
          <Link to='/'><img className='logo' alt='' src={require('../../images/logo.png')} /></Link>
          <ul>
            {
              navList.map((item, index) => {
                return (
                  <li className={selected === index ? 'active' : ''} key={item.id}><Link to={item.to}>{item.name}</Link></li>
                )
              })
            }
            <li><a target="_blank" rel="noopener noreferrer" href='https://www.lagou.com/gongsi/594252.html'>加入我们</a></li>
          </ul>
          <div className='header-phone'>
            <img className='phone-img' alt='' src={require('../../images/phone.png')} />
            {phone}
          </div>
        </div>
      </div>
    )
  }
}
export default Header;
