import  AnalysysAgent from 'ans-javascript-sdk/sdk/AnalysysAgent_JS_SDK.es6.min.js'

let oak = {}

oak.ans = {
  init: function(data){
    AnalysysAgent.init(data)
  },
  alias: function(data){
    AnalysysAgent.alias(data);
  },
  profileSetOnce: function(data){
    AnalysysAgent.profileSetOnce(data);
  },
  pageView: function(name, props, callback){
    AnalysysAgent.pageView(name, props, callback);
  },
  track: function(eventName, eventInfo, callback){
    AnalysysAgent.track(eventName, eventInfo, callback);
  }
}

export default oak