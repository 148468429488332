import React from 'react';
import axios from 'axios'
import CryptoJS from 'crypto-js/crypto-js'
import { message } from 'antd';
import './getverifycode.css';

class Getverifycode extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      errmsg: '',
      phone_number: '',
      code: '',
      time: 59,
      countDown: false,
      url: 'https://e7cb0126e55b49fdaeceed6dad7c2104-cn-beijing.alicloudapi.com',
      appid: '49ecb37c52b24712bbed1a8b7fb8db8e',
      appkey: '7b51f99966594069b6f9421bd5846117',
    }
  }
  
  //获取验证码
  getCode(phoneNumber) {
    const { url, appid } = this.state;
    const _valid = this.valid(phoneNumber);
    if (_valid) {
      this.setState({
        errmsg: _valid
      })
      return false
    }
    const timestamp = new Date().getTime();
    const random = Math.random().toString().slice(2);
    axios.request({
      url: `${url}/api/sms/sendCode`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'x-app-id': appid,
        'x-timestamp': timestamp,
        'x-nonce': random,
        'x-signature': this.creatSign(appid, timestamp, random)
      },
      params: {
        phone: phoneNumber
      }
    }).then(res=> {
      message.success('验证码已发送');
      this.setState({
        session_code: res.session_code,
        errmsg: '',
        countDown: true
      })
      this.timeCountDown()
    }).catch(() => {
      this.setState({
        errmsg: '获取验证码失败'
      })
    })
  }
  //立即下载按钮事件
  downLoadHandle = () => {
    window._hmt.push(['_trackEvent', '产品服务', '【弹窗按钮】立即下载']);
    const { url, appid, phone_number, code } = this.state;
    const timestamp = new Date().getTime();
    const random = Math.random().toString().slice(2);
    axios.request({
      url: `${url}/api/sms/verifyCode`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'x-app-id': appid,
        'x-timestamp': timestamp,
        'x-nonce': random,
        'x-signature': this.creatSign(appid, timestamp, random)
      },
      params: {
        phone: phone_number,
        code
      }
    }).then(res => {
      if(res.status === 200){
        sessionStorage.setItem('verify', 'download');
        this.hideDialog();
        this.props.onDownload(this.props.download_file)
      }
    }).catch(() => {
      this.setState({
        errmsg: '手机号或验证码错误，请重新输入'
      })
    })
  }
  //重新获取验证码倒计时
  timeCountDown() {
    let time = this.state.time;
    let timeDown = setInterval(() => {
      this.setState({
        time: time--
      })
      if (time === 1) {
        this.setState({
          countDown: false,
          time: 59
        })
        clearInterval(timeDown)
      }
    }, 1000);
  }
  //获取验证码改变的值
  inputChangeCode(event) {
    this.setState({
      code: event.target.value
    })
  }
  //获取手机号改变的值
  inputChange(event) {
    this.setState({
      phone_number: event.target.value
    })
  }
  //验证手机号
  valid(phoneNumber) {
    if (!phoneNumber) {
      return '请填写手机号'
    }
    if (phoneNumber.length !== 11) {
      return '手机号码必须是11位，请重新输入'
    }
    if (!/^[1][3,4,5,6,7,8,9][0-9]{9}$/.test(phoneNumber)) {
      return '请输入正确的手机号';
    }
    return false;
  }
  //验证手机号和验证码
  verify(phone_number, code) {
    if (!phone_number && !code) {
      return '请填写全部信息'
    }
    if (!phone_number) {
      return '请填写手机号'
    }
    if (phone_number.length !== 11) {
      return '手机号码必须是11位，请重新输入'
    }
    if (!code) {
      return '请输入验证码'
    }
  }
  //云片签名
  creatSign(appid, timestamp, random){
    const { appkey } = this.state;
    let stringToSign = appid+timestamp+random;
    let hmacSha256 = CryptoJS.HmacSHA256(stringToSign, appkey);
    let hashInHex64 = CryptoJS.enc.Hex.stringify(hmacSha256);
    return hashInHex64
  }
  hideDialog(){
    this.props.OnhideDialog()
  }
  render() {
    const {  errmsg, phone_number, time, countDown } = this.state;
    return (
      <div className='dialog-wrap'>
        <div className='dialog-shadow' onClick={this.hideDialog.bind(this)}></div>
        <div className='dialog-main'>
          <h2>申请下载会员行业报告</h2>
          <p>请您提交如下信息，即可获得下载链接</p>
          <div className='dialog-input'>
            <div className='dialog-item'>
              <input placeholder='手机号码' onChange={this.inputChange.bind(this)} />
              {
                countDown
                ? (
                  <div className='verification-code'>{time}s</div>
                ) : (
                  <div className='verification-code'  onClick={this.getCode.bind(this, phone_number)}>获取验证码</div>
                )
              }
            </div>
            <div className='dialog-item border-none'><input placeholder='手机验证码' onChange={this.inputChangeCode.bind(this)} /></div>
          </div>
          <div className='dialog-errmsg'>{errmsg}</div>
          <div className='dialog-btn' onClick={this.downLoadHandle}>立即下载</div>
          <img alt="" className='close' onClick={this.hideDialog.bind(this)} src={require('../../images/close.png')} />
        </div>
      </div>
    )
  }
}
export default Getverifycode;
