import axios from 'axios'
import CryptoJS from 'crypto-js/crypto-js'
import { baseUrl, key, secret } from './env'

axios.defaults.baseURL = baseUrl;

const oakRequest = function(option = {}, method = "GET") {
  let contentType = 'application/x-www-form-urlencoded';
  //let _Headers = 'X-Ca-Key' + ":" + key + "\n" + "X-Ca-Stage" + ":" + "RELEASE" + "\n";
  let _Headers = `X-Ca-Key:${key}\nX-Ca-Stage:RELEASE\n`;
  let signatureHeaders = 'X-Ca-Key,X-Ca-Stage';
  let paramsData = '';
  let arr = Object.keys(option.params).sort(); 
  for(var k in arr){
      if(option.params[arr[k]] || option.params[arr[k]] === 0){
        paramsData += arr[k] + "=" + option.params[arr[k]] + "&"
      }else{
        paramsData += arr[k] + "&"
      }
  }
  let _Url = '';
  if(paramsData.length){
    _Url =option.url + "?" + paramsData.substring(0,paramsData.length-1)
  }else{
    _Url =option.url
  }
  //let stringToSign = method + "\n" + contentType + "\n" + "\n" + "\n" + "\n" +  _Headers +  _Url;
  let stringToSign = `${method}\n${contentType}\n\n\n\n${_Headers}${_Url}`;
  let hmacSha256 = CryptoJS.HmacSHA256(stringToSign, secret);
  let hashInBase64 = CryptoJS.enc.Base64.stringify(hmacSha256);
  var options = {
    ...option,
    url: `${baseUrl}${option.url}`,
    headers: {
      ...option.header,
      'Accept': contentType,
      'content-type': contentType,
      'X-Ca-Key': key,
      "X-Ca-Stage": "RELEASE",
      'X-Ca-Signature': hashInBase64,
      'X-Ca-Signature-Headers': signatureHeaders,
    },
    method
  }
  return new Promise((resolve, reject) => {
    axios.request(options).then(res => {
      const { data } = res;
      const { ok } = data;
      ok ? resolve(data) : reject(data)
    }).catch(err => {
      reject(err)
    })
  })
}

const requestHelper = (url, options, method) => {
  return oakRequest({ url, params: { ...options } }, method)
}

//获取首页类目列表
const getIndexCategory = (options) => {
  return requestHelper('/api/mall/categories', options, 'GET')
}
//获取首页类目下的spu
const getspusList = (options) => {
  return requestHelper('/api/mall/products_by_title', options, 'GET')
}

export {
  getIndexCategory,
  getspusList
}