import React from 'react';
import './contact.css';
import Header from '../../components/header/header'
import Footer from '../../components/footer/footer'
import { phone, email, service_url } from '../../config/env'
import oak from '../../utils'

class Contact extends React.Component {
  constructor(props){
    super(props)
    this.state={
      list: [
        {img: require('../../images/contact1.png'), name: '把生活变成奖励'},
        {img: require('../../images/contact2.png'), name: '让消费更有意义'},
        {img: require('../../images/contact3.png'), name: '主动 价值 成长'},
      ]
    }
  }
  componentDidMount(){
    oak.ans.pageView('官网联系我们浏览', {namespace: 'xs_web'})
    document.documentElement.scrollTop = 0;
  }
  toService(){
    oak.ans.track('oak_consult_click', {namespace: 'xs_web'});
    window._hmt.push(['_trackEvent', '联系我们', '【按钮】立即联系']);
    window.location.href = service_url
  }
  toEmail(){
    oak.ans.track('oak_email_click', {namespace: 'xs_web'});
    window._hmt.push(['_trackEvent', '联系我们', '【按钮】发送邮件']);
  }
  render() {
    const { list } = this.state;
    return (
      <div>
        <Header selected={2} />
        <div className='banner pc-banner'>
          <img alt='' src={require('../../images/contact.png')} />
        </div>
        <div className='banner mobile-banner'>
          <img alt='' src={require('../../images/mobile-contact.png')} />
        </div>
        <div className='container'>
          <div className='about'>公司简介</div>
          <div className='about-desc'>高光时刻成立于2018年，是国内领先一站式付费会员制权益服务商，致力于付费会员这一新的智能商业模式的创新与演进，创始团队来自于腾讯、平安、苏宁、乐信等，在会员服务领域拥有丰富的行业积累与成功经验，并在多行业展开了多元和深入的合作。高光时刻秉承“让消费更有意义”理念，面向C端用户提供各类会员权益，从出行到生活全方位服务于客户。面向B端企业，深度整合供应链，结合平台用户特征，推出定制化付费会员体系，帮助企业提高用户价值,实现新业务创收。</div>
          <div className='about-list'>
            {
              list.map((item) => {
                return (
                  <div className='about-item' key={item.name}>
                    <img alt='' src={item.img} />
                    <p>{item.name}</p>
                  </div>
                )
              })
            }
          </div>
          <div className='about'>联系我们</div>
          <div className='contact-list'>
            <div className='contact-item'>
              <h4>电话联系</h4>
              <p>每天9:00-20:00</p>
              <button className='contact-phone'>{phone}</button>
            </div>
            <div className='contact-item'>
              <h4>在线咨询</h4>
              <p>每天9:00-20:00</p>
              <button onClick={this.toService.bind(this)}>立即联系</button>
            </div>
            <div className='contact-item'>
              <h4>邮件联系</h4>
              <p>{email}</p>
              <button onClick={this.toEmail.bind(this)}><a href="mailto:marketing@oakblack.com">发送邮件</a></button>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    )
  }
}
export default Contact;
